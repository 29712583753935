import { render, staticRenderFns } from "./ReqKakaoReservationRequest.vue?vue&type=template&id=40b90b26&scoped=true"
import script from "./ReqKakaoReservationRequest.vue?vue&type=script&lang=js"
export * from "./ReqKakaoReservationRequest.vue?vue&type=script&lang=js"
import style0 from "./ReqKakaoReservationRequest.vue?vue&type=style&index=0&id=40b90b26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b90b26",
  null
  
)

export default component.exports