var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"box box-solid"},[_c('div',{staticClass:"box-body form-horizontal"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("제목")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing)return;_vm.title=$event.target.value}}})])]),(_vm.noticeNo > 0)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("작성자")]),_c('div',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.registeredBy))])]):_vm._e(),(_vm.noticeNo > 0)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("등록일")]),_c('div',{staticClass:"col-sm-9"},[_vm._v(_vm._s(_vm.viewDate(_vm.registeredDate)))])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("공지")]),_c('div',{staticClass:"col-sm-9 radio"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.published),expression:"published"}],attrs:{"type":"radio"},domProps:{"value":true,"checked":_vm._q(_vm.published,true)},on:{"change":function($event){_vm.published=true}}}),_vm._v("적용 ")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.published),expression:"published"}],attrs:{"type":"radio"},domProps:{"value":false,"checked":_vm._q(_vm.published,false)},on:{"change":function($event){_vm.published=false}}}),_vm._v("적용안함 ")])])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"hidden-xs col-sm-3 control-label"}),_c('div',{staticClass:"col-sm-9"},[_c('editor',{attrs:{"api-key":"02p3raa9urge93k9p07ljuvfbkovzy2wrad7dprwav8xe682","init":{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'help | code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
            }},model:{value:(_vm.contents),callback:function ($$v) {_vm.contents=$$v},expression:"contents"}})],1)]),_c('div',{staticClass:"form-group text-center"},[_c('button',{staticClass:"btn btn-default",staticStyle:{"margin-right":"10px"},attrs:{"type":"button"},on:{"click":_vm.goBack}},[_vm._v(" 뒤로 ")]),_c('button',{staticClass:"btn btn-default",attrs:{"type":"button","disabled":_vm.buttonDissabled},on:{"click":_vm.doRegister}},[_vm._v("저장")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }