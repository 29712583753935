import { render, staticRenderFns } from "./ProdAddImg.vue?vue&type=template&id=35c76483&scoped=true"
import script from "./ProdAddImg.vue?vue&type=script&lang=js"
export * from "./ProdAddImg.vue?vue&type=script&lang=js"
import style0 from "./ProdAddImg.vue?vue&type=style&index=0&id=35c76483&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c76483",
  null
  
)

export default component.exports