import { render, staticRenderFns } from "./ChannelMonth.vue?vue&type=template&id=0dcc15ee&scoped=true&lang=ko"
import script from "./ChannelMonth.vue?vue&type=script&lang=js"
export * from "./ChannelMonth.vue?vue&type=script&lang=js"
import style0 from "./ChannelMonth.vue?vue&type=style&index=0&id=0dcc15ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dcc15ee",
  null
  
)

export default component.exports